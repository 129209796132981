<template>
  <v-dialog
    v-model="deleteUniformModal"
    width="800px"
    :retain-focus="false"
  >
    <v-card
      class="py-5 pb-5"
    >
      <v-card-title>
        <div class="text-center text-h5" style="width: 100%">
          Você deseja excluir esse Uniforme?
        </div>
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-no-wrap justify-center">
          <v-avatar
            tile
            left
            size="150px"
            color="#F7F7F7"
            class="ma-3 uniform-image"
          >
            <v-img
              :src="uniform.image"
              contain
              max-height="70%"
              @error="imageErrorHandler()"
            />
          </v-avatar>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-row justify="center">
          <v-btn
            class="py-4"
            color="primary"
            type="submit"
            :disabled="saving"
            @click="deleteUniform()"
          >
            <v-progress-circular
              v-if="saving"
              indeterminate
              size="25"
              width="3"
              class="px-6"
              color="white"
            />
            <span
              v-else
              class="px-6"
            >
              Sim
            </span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const imageOffIcon = require('@/assets/icons/image-off.png')

export default {
  props: {
    uniformData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      saving: false,
      id: null,
      uniform: {
        image: ''
      }
    }
  },
  computed: {
    deleteUniformModal: {
      get () {
        return this.$store.state.deleteUniformModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'deleteUniformModal',
          value: val
        })
      }
    }
  },
  watch: {
    deleteUniformModal (val) {
      if (val) {
        const { _id, image } = this.uniformData
        this.id = _id
        this.uniform.image = image
      } else {
        this.uniform = {
          image: ''
        }
        this.id = null
      }
    }
  },
  methods: {
    deleteUniform () {
      this.saving = true
      this.$http.delete(`/uniforms/${this.id}`)
        .then((res) => {
          this.saving = false
          this.deleteUniformModal = false
          this.$emit('deleted')
          this.$toast.success('Cadastro removido com sucesso.')
        })
        .catch((err) => {
          this.saving = false
          this.$toast.error(err.response.data.message)
        })
    },
    imageErrorHandler () {
      this.uniform.image = imageOffIcon
    }
  }
}
</script>
